<div class="container">
  <div>
    <section class="header">
      <img class="pointer" src="/assets/img/logos/_manual_ledger.svg" alt="waltio" [routerLink]="'/accounts'" />
    </section>

    <section class="main">
      @if (assessmentStatus) {
        <app-assessment-status
          [assessment]="assessment"
          [assessmentStatus]="assessmentStatus"
          [assessmentETA]="assessmentETA"
          [accountsSyncing]="accountsSyncing"
          (statusClick)="assessmentStatusClick()"
        ></app-assessment-status>
      }

      <nav>
        <div class="content">
          <p class="body-12-bold">Wallet Manager</p>

          <div class="content__links">
            @for (link of walletManagerLinks; track link.label) {
              <app-navigation-tile [link]="link"></app-navigation-tile>
            }
          </div>
        </div>

        <div class="content">
          <p class="body-12-bold">{{ 'TAX' | translate }}</p>

          <div class="content__links">
            @for (link of taxationLinks; track link.label) {
              <app-navigation-tile [link]="link"></app-navigation-tile>
            }
          </div>
        </div>
      </nav>
    </section>
  </div>

  <div>
    <div class="sponsorship pointer" [routerLink]="'/profile/sponsorship'">
      <img src="/assets/img/icons/sponsorship.svg" alt="sponsorship" />
      <p class="body-14-med">{{ 'INVITE_FRIEND' | translate }}</p>

      <div class="sponsorship__chip">
        <p class="body-13-med">{{ 'GET_15' | translate }}</p>
      </div>
    </div>

    <app-plan-status
      [assessment]="assessment"
      [plan]="user.plan"
      [language]="language"
      [associatedFiscalYear]="associatedFiscalYear"
      [plansByFiscalYears]="plansByFiscalYears"
      (upgradePlan)="upgradePlan()"
      (upgradeAddon)="bookCall()"
    ></app-plan-status>

    <app-profile-sidenav [user]="user" [matMenuTriggerFor]="menu"></app-profile-sidenav>

    <mat-menu #menu="matMenu" yPosition="above" class="custom-menu">
      <div class="menu">
        <div class="item" [routerLink]="'/profile'">
          <p class="body-14-med">{{ 'PROFILE' | translate }}</p>
        </div>
        <div class="item body-14-med" [routerLink]="'/profile/preferences'">
          <p class="body-14-med">{{ 'PREFERENCES' | translate }}</p>
        </div>
        <div class="item body-14-med" [routerLink]="'/profile/subscription'">
          <p class="body-14-med">{{ 'SUBSCRIPTION' | translate }}</p>
        </div>
        <div class="item" [routerLink]="'/profile/sponsorship'">
          <p class="body-14-med">{{ 'REFERRAL' | translate }}</p>
          <div class="sponsorship-chip">{{ 30 | currency: 'EUR' : 'symbol' : '.2-2' }}</div>
        </div>
        <a class="item body-14-med" [href]="'https://help.waltio.co/' + language" target="_blank">
          <p class="body-14-med">{{ 'HELP_CENTER' | translate }}</p>
        </a>
      </div>

      <mat-divider></mat-divider>

      <div class="menu">
        <div class="action" (click)="logout()">
          <div class="action__icon-logout"></div>
          <p class="body-14-med">{{ 'LOGOUT' | translate }}</p>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
