import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import * as fromAssessment from '../../../taxation/store/selectors/assessment.selector';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { Link } from '../../models/link.model';
import { goToAction, pingOutUserAction, pushTagAction } from '../../store/actions/shared.action';
import { NavigationTileComponent } from '../navigation-tile/navigation-tile.component';
import { PlanStatusComponent } from '../plan-status/plan-status.component';
import { ProfileSidenavComponent } from '../profile-sidenav/profile-sidenav.component';
import { AssessmentStatusComponent } from '../assessment-status/assessment-status.component';
import { TranslateModule } from '@ngx-translate/core';
import { logoutAction } from '../../../authentication/store/actions/authentication.action';
import { Assessment, AssessmentStatus } from '../../../taxation/models/assessment.model';
import { User, UserAddon } from '../../../taxation/models/user.model';
import { unsyncAssessmentAction } from '../../../taxation/store/actions/assessment.action';
import { UtilsService } from '../../services/utils.service';
import { UpgradeFishingDialogComponent } from '../../../taxation/components/dialogs/upgrade-fishing-dialog/upgrade-fishing-dialog.component';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import * as fromAccount from '../../../taxation/store/selectors/account.selector';
import { AvailableAddons, FiscalYearPlanEstimate, PaymentEstimateV3 } from '../../../taxation/models/payment.model';
import {
  loadAvailableAddonsAction,
  loadPlansByFiscalYearsAction,
} from '../../../taxation/store/actions/payment.action';
import { UserAccount } from '../../../taxation/models/user-account.model';
import {
  getUserAccountAction,
  loadUserAPIsAction,
  loadUserAccountsAction,
} from '../../../taxation/store/actions/account.action';
import { Tag } from '../../models/tag.model';
import { UserPreferences } from '../../models/user-preferences.model';
import { Feature } from '../../models/feature.model';
import { FeatureService } from '../../services/feature.service';
import { WelcomeDialogComponent } from '../dialogs/welcome-dialog/welcome-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: `app-sidenav`,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    AssessmentStatusComponent,
    NavigationTileComponent,
    ProfileSidenavComponent,
    PlanStatusComponent,
  ],
  templateUrl: `./sidenav.component.html`,
  styleUrls: [`./sidenav.component.scss`],
})
export class SidenavComponent implements OnInit, OnDestroy {
  currentYear = moment().year();
  walletManagerLinks: Link[] = [
    {
      label: `MENU.ACCOUNTS`,
      routeIcon: `/assets/img/icons/wallet.svg`,
      routeActiveIcon: `/assets/img/icons/wallet-active.svg`,
      route: `/accounts`,
    },
  ];
  taxationLinks: Link[] = [
    {
      label: `MENU.TAX_REPORT`,
      routeIcon: `/assets/img/icons/document.svg`,
      routeActiveIcon: `/assets/img/icons/document-active.svg`,
      route: `/report/${this.currentYear}`,
    },
    {
      label: `MENU.TRANSACTIONS`,
      routeIcon: `/assets/img/icons/transactions.svg`,
      routeActiveIcon: `/assets/img/icons/transactions-active.svg`,
      route: `/transactions`,
    },
  ];

  assessment: Assessment;
  user: User;
  userPreferences: UserPreferences;
  language: string;
  associatedFiscalYear: number;
  accountsSyncing = false;
  availableAddons: AvailableAddons;
  hasPaid = true;

  userAccounts: UserAccount[];
  accountsStatusCheckInterval: any;
  upgradeFishingEstimate: PaymentEstimateV3;
  assessmentStatus: AssessmentStatus;
  plansByFiscalYears: Map<string, FiscalYearPlanEstimate>;
  assessmentETA = 0;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    public readonly utilsService: UtilsService,
    private readonly featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.accountStore$.dispatch(loadUserAccountsAction());

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessmentStatus),
        map((assessmentStatus: AssessmentStatus) => (this.assessmentStatus = assessmentStatus))
      )
      .subscribe();

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessment),
        map((assessment: Assessment) => {
          this.assessment = assessment;
        })
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectUserAccounts),
        map((userAccounts: UserAccount[]) => {
          this.userAccounts = userAccounts;

          if (this.userAccounts) {
            this.accountsSyncing = this.userAccounts.some((account) => account.status === `UPLOADED`);

            if (!this.accountsStatusCheckInterval) {
              this.accountsStatusCheckInterval = setInterval(() => {
                this.checkAccountsStatus();
              }, 5000);
            }
          }
        })
      )
      .subscribe();

    this.authStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUser),
        map((user: User) => {
          this.user = user;

          if (this.user) {
            this.associatedFiscalYear = user.associatedFiscalYear;
            this.taxationLinks[0].route = `/report/${this.associatedFiscalYear}`;

            this.checkDashboardFeature();
            this.checkOpportunitiesFeature();
          } else {
            if (!this.utilsService.isFiscalYearSwitchable(this.currentYear)) {
              this.currentYear -= 1;
            }
            this.taxationLinks[0].route = `/report/${this.currentYear}`;
          }
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        })
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectUpgradeFishingEstimate),
        map((upgradeFishingEstimate: PaymentEstimateV3) => {
          this.upgradeFishingEstimate = upgradeFishingEstimate;

          if (this.upgradeFishingEstimate) {
            this.hasPaid =
              this.upgradeFishingEstimate.due === 0 ||
              this.utilsService.isRecommendedPlanOverkilled(this.upgradeFishingEstimate);
          }
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectLanguage),
        map((language: string) => (this.language = language))
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectAvailableAddons),
        map((availableAddons: AvailableAddons) => {
          this.availableAddons = availableAddons;

          if (!this.availableAddons) {
            this.paymentStore$.dispatch(loadAvailableAddonsAction());
          }
        })
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPlansByFiscalYears),
        map((plansByFiscalYears: Map<string, FiscalYearPlanEstimate>) => {
          if (plansByFiscalYears) {
            this.plansByFiscalYears = plansByFiscalYears;
          }
        })
      )
      .subscribe();

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessmentETA),
        map((assessmentETA: number) => {
          this.assessmentETA = assessmentETA;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.accountsStatusCheckInterval) {
      clearInterval(this.accountsStatusCheckInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.utilsService.clearPWACache();
    this.sharedStore$.dispatch(pingOutUserAction());
    this.assessmentStore$.dispatch(unsyncAssessmentAction());
    this.assessmentStore$.dispatch(logoutAction());
  }

  upgradePlan(): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `upgrade-nav-bar`,
      checkoutType: `PLAN`,
      offer: this.upgradeFishingEstimate.selectedPlan,
      fiscalYear: this.currentYear,
    });
  }

  navigateTo(link: Link): void {
    this.sharedStore$.dispatch(goToAction({ url: link.route }));
  }

  assessmentStatusClick(): void {
    if (this.assessmentStatus === `NEED_ACCOUNT_ADD`) {
      this.sharedStore$.dispatch(goToAction({ url: `/accounts/add` }));
    } else if (this.assessmentStatus === `NEED_PLAN_UPGRADE`) {
      this.upgradeFishing();
    }
  }

  upgradeFishing(): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `upgrade-assessment-status`,
      checkoutType: `PLAN`,
      offer: this.upgradeFishingEstimate.selectedPlan,
      fiscalYear: this.assessment.fiscalYear,
    });
  }

  upgradeAddon(): void {
    const offer: UserAddon = this.availableAddons.AOCARE001 && this.availableAddons.AOCARE002 ? null : `AOCARE002`;

    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `upgrade-nav-bar-addons`,
      checkoutType: `ADDON`,
      offer,
    });
  }

  checkAccountsStatus(): void {
    if (this.accountsSyncing) {
      this.userAccounts
        .filter((account: UserAccount) => account.status === `UPLOADED`)
        .forEach((account: UserAccount) => {
          this.accountStore$.dispatch(getUserAccountAction({ accountId: account.accountId }));
        });
    } else {
      this.accountsStatusCheckInterval = clearInterval(this.accountsStatusCheckInterval);
      this.paymentStore$.dispatch(loadPlansByFiscalYearsAction());
      this.accountStore$.dispatch(loadUserAPIsAction());
    }
  }

  bookCall(): void {
    let url = ``;
    switch (this.userPreferences.language) {
      case `fr`:
        url = `https://meet.brevo.com/waltio/full-service`;
        break;
      case `en`:
        url = `https://meet.brevo.com/waltio/full-service-en`;
        break;
      case `es`:
        url = `https://meet.brevo.com/waltio/full-service-es`;
        break;
    }

    const tag: Tag = {
      event: `call_full_service_profil`,
    };
    this.sharedStore$.dispatch(pushTagAction({ tag }));

    window.open(url, `_blank`);
  }

  openWelcomeDialog(): void {
    const dialogRef = this.utilsService.openDialog(WelcomeDialogComponent, `auto`, `auto`, {
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          localStorage.setItem(`welcome-dialog`, `true`);
        })
      )
      .subscribe();
  }

  checkDashboardFeature(): void {
    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFeatures(`dashboard`)),
        map((features: Feature[]) => {
          if (features) {
            this.featureService
              .isCustomersListFeatureEnabled(features[0], this.user.email)
              .pipe(
                map((res: boolean) => {
                  if (res && !this.walletManagerLinks.some((link) => link.label === `MENU.DASHBOARD`)) {
                    this.walletManagerLinks.push({
                      label: `MENU.DASHBOARD`,
                      routeIcon: `/assets/img/icons/dashboard.svg`,
                      routeActiveIcon: `/assets/img/icons/dashboard-active.svg`,
                      route: `/dashboard`,
                      beta: true,
                    });
                  }

                  if (res && !localStorage.getItem(`welcome-dialog`) && !environment.production) {
                    this.openWelcomeDialog();
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }

  checkOpportunitiesFeature(): void {
    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFeatures(`opportunities`)),
        map((features: Feature[]) => {
          if (features) {
            this.featureService
              .isCustomersListFeatureEnabled(features[0], this.user.email)
              .pipe(
                map((res: boolean) => {
                  if (res && !this.walletManagerLinks.some((link) => link.label === `MENU.OPPORTUNITIES`)) {
                    this.walletManagerLinks.push({
                      label: `MENU.OPPORTUNITIES`,
                      routeIcon: `/assets/img/icons/opportunities.svg`,
                      routeActiveIcon: `/assets/img/icons/opportunities-active.svg`,
                      route: `/opportunities`,
                      beta: true,
                    });
                  }

                  if (res && !localStorage.getItem(`welcome-dialog`) && !environment.production) {
                    this.openWelcomeDialog();
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }
}
