/* eslint-disable no-useless-escape */
import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService, User as AuthUser, GenericError } from '@auth0/auth0-angular';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, fromEvent } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { LoaderComponent } from './authentication/components/loader/loader.component';
import * as fromAuth from './authentication/store/selectors/authentication.selector';
import { SecretDialogComponent } from './shared/components/secret-dialog/secret-dialog.component';
import { LANGUAGES } from './shared/constants/language.constant';
import { UtilsService } from './shared/services/utils.service';
import {
  checkUserExistenceAction,
  loadFeaturesAction,
  loadGTMAction,
  pingOutUserAction,
  pushTagAction,
  setPartnerAction,
} from './shared/store/actions/shared.action';
import * as fromShared from './shared/store/selectors/shared.selector';
import { CheckoutSuccessDialogComponent } from './taxation/components/dialogs/checkout-success-dialog/checkout-success-dialog.component';
import { unsyncAssessmentAction } from './taxation/store/actions/assessment.action';
import * as fromAssessment from './taxation/store/selectors/assessment.selector';
import { TRACKED_ROUTES } from './shared/constants/tracking.constant';
import { loadUserAction } from './authentication/store/actions/authentication.action';
import { stopFeedStreamAction } from './portfolio-manager/store/actions/insight.action';

@Component({
  selector: `app-root`,
  standalone: true,
  imports: [RouterModule, LoaderComponent],
  templateUrl: `./app.component.html`,
})
export class AppComponent implements OnInit, OnDestroy {
  // Sets initial value to true to show loading spinner on first load
  loading = true;

  keysPressed: any = {};
  authUser: AuthUser;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore: Store<fromShared.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly assessmentStore: Store<fromAssessment.State>,
    private readonly insightStore$: Store<fromShared.State>,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly utilsService: UtilsService,
    private readonly location: Location
  ) {
    const locationPath = this.location.path();

    // Avoid infinite callback loop
    if (!locationPath.startsWith(`/callback`)) {
      localStorage.setItem(`auth_callback_url`, locationPath);
    }

    // Handle auth redirects
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        map((event: any) => {
          if (event instanceof NavigationEnd) {
            this.loading = false;

            if (event.url !== `/` && !event.url.includes(`/callback`)) {
              if (event.url.includes(`/thank-you?id=`)) {
                this.utilsService.openDialog(CheckoutSuccessDialogComponent, `384px`, `auto`, {
                  paymentId: event.url.split(`?`)[1].split(`=`)[1],
                });
              }

              const referralUrlRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?!.*\d)[A-Za-z/]+$/;

              // Check if the current route is a tracked route
              if (
                TRACKED_ROUTES.includes(event.url) ||
                event.url.includes(`/report/`) ||
                event.url.includes(`/partner/`) ||
                referralUrlRegex.test(event.url)
              ) {
                // Send GTM tag
                this.sharedStore.dispatch(
                  pushTagAction({
                    tag: {
                      event: `page_view`,
                    },
                  })
                );
              }
            }
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.utilsService.clearPWACache();
    this.authStore.dispatch(loadUserAction());
    this.sharedStore.dispatch(loadGTMAction());
    this.sharedStore.dispatch(loadFeaturesAction());
    // Reset partner
    this.sharedStore.dispatch(setPartnerAction({ partner: null }));

    this.setupLanguage();

    this.authStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectAuthUser),
        filter((user: AuthUser) => !!user),
        tap((user: AuthUser) => {
          this.authUser = user;
          this.setupSecretCommand();
        })
      )
      .subscribe();

    // Handle auth0 errors
    this.authService.error$
      .pipe(
        takeUntil(this.destroy$),
        filter((error: Error) => error instanceof GenericError && error.error === `login_required`),
        mergeMap(() => this.authService.loginWithRedirect())
      )
      .subscribe();
  }

  @HostListener(`window:beforeunload`)
  async ngOnDestroy(): Promise<void> {
    this.insightStore$.dispatch(stopFeedStreamAction());
    this.sharedStore.dispatch(pingOutUserAction());
    this.assessmentStore.dispatch(unsyncAssessmentAction());

    this.destroy$.next();
    this.destroy$.complete();
  }

  setupLanguage(): void {
    const browserLang = this.translateService.getBrowserLang();

    this.translateService.addLangs(Array.from(LANGUAGES.keys()));
    this.translateService.setDefaultLang(browserLang);
    this.translateService.use(browserLang);
  }

  setupSecretCommand(): void {
    fromEvent<KeyboardEvent>(document, `keydown`)
      .pipe(
        takeUntil(this.destroy$),
        map((event: KeyboardEvent) => {
          this.keysPressed[event.key] = true;

          if (this.unlockSecretDialog()) {
            this.openSecretDialog();
          }
        })
      )
      .subscribe();

    fromEvent<KeyboardEvent>(document, `keyup`)
      .pipe(
        takeUntil(this.destroy$),
        map((event: KeyboardEvent) => {
          delete this.keysPressed[event.key];
        })
      )
      .subscribe();
  }

  unlockSecretDialog(): boolean {
    return (
      this.keysPressed.Control && this.keysPressed.w && this.keysPressed.o && this.authUser.email.includes(`@waltio.co`)
    );
  }

  openSecretDialog(): void {
    const dialogRef = this.utilsService.openDialog(SecretDialogComponent, `450px`, `auto`);

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap((email: string) => {
          if (email || email === ``) {
            this.authStore.dispatch(pingOutUserAction());
            this.assessmentStore.dispatch(unsyncAssessmentAction());
            this.sharedStore.dispatch(checkUserExistenceAction({ email }));
          }
        })
      )
      .subscribe();
  }
}
