/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import { environment } from '../../../environments/environment';
import { SUBSCRIPTION_RENEW_DAY, SUBSCRIPTION_RENEW_MONTH } from '../../taxation/constants/payment.constant';
import { PaymentEstimateV3 } from '../../taxation/models/payment.model';
import { Transaction, TransactionWarning } from '../../taxation/models/transaction.model';
import { FiscalResidency } from '../../taxation/models/user.model';
import { BasicDialogComponent } from '../components/dialogs/basic-dialog/basic-dialog.component';
import { LANGUAGES } from '../constants/language.constant';
import { Feature, FiscalPeriodInfos } from '../models/feature.model';
import { Page } from '../models/page.model';
import { RouterStateUrl } from '../models/router-state-url.model';
import { NgxDatePipe } from '../pipes/ngx-date.pipe';
import { IntercomService } from './intercom.service';
import { Account } from '../../taxation/models/account.model';
import { CurrencyDecimalPipe } from '../pipes/currency-decimal.pipe';
import { UserPreferences } from '../models/user-preferences.model';

@Injectable({ providedIn: `root` })
export class UtilsService {
  constructor(
    private readonly ngxDatePipe: NgxDatePipe,
    private readonly currencyDecimalPipe: CurrencyDecimalPipe,
    private readonly translateService: TranslateService,
    private readonly intercomService: IntercomService,
    private readonly dateAdapter: DateAdapter<any>,
    private readonly dialog: MatDialog
  ) {}

  /**
   * Order transactions by date with a fake transaction
   *
   * @param transactions
   * @returns
   */
  getTransactionsOrderedByDate(transactionsPage: Page<Transaction>): Transaction[] {
    let orderedTransactions = [];
    if (transactionsPage.content.length > 0) {
      transactionsPage.content.sort((t1: Transaction, t2: Transaction) =>
        t1.transactionDate < t2.transactionDate ? 1 : -1
      );

      // Sort by date desc
      orderedTransactions = [...transactionsPage.content];

      orderedTransactions.splice(0, 0, {
        id: null,
        type: null,
        subType: null,
        transactionDate: this.ngxDatePipe.transform(orderedTransactions[0].transactionDate, `d LLLL yyyy`),
      });

      for (let i = 0; i < orderedTransactions.length - 1; i++) {
        const currentDate = orderedTransactions[i].id
          ? this.ngxDatePipe.transform(orderedTransactions[i].transactionDate, `d LLLL yyyy`)
          : orderedTransactions[i].transactionDate;

        const nextDate = this.ngxDatePipe.transform(orderedTransactions[i + 1].transactionDate, `d LLLL yyyy`);

        if (currentDate !== nextDate) {
          orderedTransactions.splice(i + 1, 0, {
            id: null,
            transactionDate: this.ngxDatePipe.transform(orderedTransactions[i + 1].transactionDate, `d LLLL yyyy`),
          });
        }
      }
    }

    return orderedTransactions;
  }

  /**
   * Get cookie by name
   *
   * @param name
   * @returns
   */
  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(`;`)
        .map((c) => c.trim())
        .filter((cookie) => cookie.startsWith(`${name}=`))
        .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || ``
    );
  }

  /**
   * Switch app language
   *
   * @param language
   */
  switchLanguage(language: string): void {
    const lang = LANGUAGES.has(language) ? language : `en`;
    this.translateService.use(lang);
    moment.locale(lang === `en` ? `en-gb` : lang);
    this.dateAdapter.setLocale(lang === `en` ? `en-gb` : lang);
    this.intercomService.updateLanguage(lang);
  }

  /**
   * Serialize route to get url, queryParams and params
   *
   * @param route
   * @returns url, queryParams and params
   */
  serializeRoute(route: string): RouterStateUrl {
    const [url, allParams] = route.split(`?`);
    const params = allParams?.split(`&`);
    const queryParams: Params = {};
    params?.forEach((param: string) => {
      const [key, value] = param.split(`=`);
      queryParams[key] = value;
    });

    return {
      url,
      queryParams,
      params: {},
    };
  }

  /**
   * Check if today is after the 1st October and if the associated fiscal year is not the current year
   *
   * @param associatedFiscalYear
   * @returns
   */
  isFiscalYearSwitchable(associatedFiscalYear: number): boolean {
    const currentYear = moment().year();
    const today = moment();
    const renewDate = moment({
      year: currentYear,
      month: SUBSCRIPTION_RENEW_MONTH,
      date: SUBSCRIPTION_RENEW_DAY,
    });

    if (!environment.production) {
      return associatedFiscalYear <= currentYear;
    } else {
      if (currentYear === associatedFiscalYear) {
        return today >= renewDate;
      } else {
        return associatedFiscalYear < currentYear;
      }
    }
  }

  openDialog(
    component: any = BasicDialogComponent,
    width = `706px`,
    height = `680px`,
    data: any = {}
  ): MatDialogRef<any, any> {
    const panelClass: string[] = [`custom-dialog-container`];
    const backdropClass: string[] = [`custom-dialog-backdrop`];
    if (data.panelClass) panelClass.push(data.panelClass);
    if (data.backdropClass) backdropClass.push(data.backdropClass);
    if (data.hasBackdrop === undefined) data.hasBackdrop = true;
    if (data.enterAnimationDuration === undefined) data.enterAnimationDuration = 300;
    if (data.maxHeight === undefined) data.maxHeight = `auto`;
    if (data.minHeight === undefined) data.minHeight = `auto`;
    data.disableClose = data.disableClose === undefined ? false : data.disableClose;

    return this.dialog.open(component, {
      disableClose: data.disableClose,
      hasBackdrop: data.hasBackdrop,
      autoFocus: false,
      width,
      height,
      panelClass,
      backdropClass,
      data,
      enterAnimationDuration: data.enterAnimationDuration,
      maxHeight: data.maxHeight,
      minHeight: data.minHeight,
    });
  }

  getFiscalPeriodInfos(countryCode: string, taxReportPeriodFeature: Feature): FiscalPeriodInfos {
    const fiscalResidency: FiscalResidency = countryCode.toUpperCase() as FiscalResidency;
    const periodStart = moment(taxReportPeriodFeature.parameters.from);
    const periodEnd = moment(taxReportPeriodFeature.parameters.to);
    const today = moment();
    const isOpened = taxReportPeriodFeature?.enabled ? today.isBetween(periodStart, periodEnd, `day`, `[]`) : false;

    return {
      fiscalResidency,
      periodStart,
      periodEnd,
      isOpened,
    };
  }

  isRecommendedPlanOverkilled(paymentEstimate: PaymentEstimateV3): boolean {
    if (!paymentEstimate) return false;

    switch (paymentEstimate.recommendedPlan) {
      case `PLN000`:
        return true;
      case `PLN300`:
        return paymentEstimate.currentPlan !== `PLN000`;
      case `PLN500`:
        return paymentEstimate.currentPlan === `PLN800` || paymentEstimate.currentPlan === `PLN500`;
      case `PLN800`:
        return false;
    }
  }

  getCriticalWarnings(transaction: Transaction): TransactionWarning[] {
    return transaction?.warnings?.filter((warning: TransactionWarning) => warning.criticality === `HIGH`) || [];
  }

  clearPWACache(): void {
    if (`caches` in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  isBlockchainAccount(blockchains: Account[], accountKey: string): boolean {
    return blockchains?.some((blockchainAccount: Account) => blockchainAccount.key === accountKey);
  }

  formatAmount(
    amount: number,
    currency: string,
    userPreferences: UserPreferences,
    stablecoins: string[],
    fiats: string[]
  ): string {
    const formattedAmount = this.currencyDecimalPipe.transform(
      amount,
      userPreferences,
      stablecoins,
      fiats,
      ``,
      currency
    );

    return formattedAmount;
  }

  truncateAmount(amount: string, length = 12): string {
    return amount.length > length ? amount.slice(0, length) + `...` : amount;
  }

  transformAmount(
    amount: number,
    currency: string,
    userPreferences: UserPreferences,
    stablecoins: string[],
    fiats: string[]
  ): string {
    const formattedAmount = this.formatAmount(amount, currency, userPreferences, stablecoins, fiats);

    return this.truncateAmount(formattedAmount);
  }
}
