/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { PlatformFee } from '../../models/user-fees.model';
import { Yield } from '../../models/yield-opportunity.model';

// eslint-disable-next-line no-shadow
export enum OpportunityActions {
  computeCheapestPlatforms = `[Opportunity] Compute Cheapest Platforms`,
  SetCheapestPlatforms = `[Opportunity] Set Cheapest Platforms`,

  ComputePlatformsFees = `[Opportunity] Compute Platforms Fees`,
  SetPlatformsFees = `[Opportunity] Set Platforms Fees`,

  ComputeMissedPassiveYield = `[Opportunity] Compute Missed Passive Yield`,
  SetMissedPassiveYield = `[Opportunity] Set Missed Passive Yield`,

  ComputeAnnualFees = `[Opportunity] Compute Annual Fees`,
  SetAnnualFees = `[Opportunity] Set Annual Fees`,

  ComputeYieldableTokens = `[Opportunity] Compute Yieldable Tokens`,
  SetYieldableTokens = `[Opportunity] Set Yieldable Tokens`,

  ComputeAvailableYields = `[Opportunity] Compute Available Yields`,
  SetAvailableYields = `[Opportunity] Set Available Yields`,
}

export const computeYieldableTokensAction = createAction(OpportunityActions.ComputeYieldableTokens);

export const setYieldableTokensAction = createAction(
  OpportunityActions.SetYieldableTokens,
  props<{ yieldableTokens: Map<string, number> }>()
);

export const computeMissedPassiveYieldAction = createAction(OpportunityActions.ComputeMissedPassiveYield);

export const setMissedPassiveYieldAction = createAction(
  OpportunityActions.SetMissedPassiveYield,
  props<{ missedPassiveYield: number }>()
);

export const computeCheapestPlatformsAction = createAction(OpportunityActions.computeCheapestPlatforms);

export const setCheapestPlatformsAction = createAction(
  OpportunityActions.SetCheapestPlatforms,
  props<{ cheapestPlatforms: PlatformFee[] }>()
);

export const computeAnnualFeesAction = createAction(OpportunityActions.ComputeAnnualFees);

export const setAnnualFeesAction = createAction(
  OpportunityActions.SetAnnualFees,
  props<{ bestAnnualFees: number; worstAnnualFees: number }>()
);

export const computePlatformsFeesAction = createAction(OpportunityActions.ComputePlatformsFees);

export const setPlatformsFeesAction = createAction(
  OpportunityActions.SetPlatformsFees,
  props<{ platformsFees: PlatformFee[] }>()
);

export const computeAvailableYieldsAction = createAction(OpportunityActions.ComputeAvailableYields);

export const setAvailableYieldsAction = createAction(
  OpportunityActions.SetAvailableYields,
  props<{ availableYields: Yield[] }>()
);
