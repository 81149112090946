/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { LiveBalanceResponse, LiveBalanceResponseItem } from '../../models/live-balance.model';
import { MarketFeed, MarketHistory } from '../../models/market.model';
import { BalanceService } from '../../services/balance.service';
import { FeedService } from '../../services/feed.service';
import { InsightService } from '../../services/insight.service';
import {
  computePortfolioValueAction,
  loadLiveBalancesAction,
  loadMarketHistoryAction,
  loadUserFeesAction,
  loadUserFeesByPlatformAction,
  loadYieldOpportunitiesAction,
  setLiveBalancesAction,
  setMarketFeedAction,
  setMarketHistoryAction,
  setPortfolioValueAction,
  setUserFeesAction,
  setUserFeesByPlatformAction,
  setYieldOpportunitiesAction,
  startFeedStreamAction,
  stopFeedStreamAction,
} from '../actions/insight.action';
import * as fromInsight from '../selectors/insight.selector';
import { YieldService } from '../../services/yield.service';
import { YieldOpportunity } from '../../models/yield-opportunity.model';
import { FeeService } from '../../services/fee.service';
import { UserFees } from '../../models/user-fees.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { Scam } from '../../../shared/models/scam.model';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { MarketService } from '../../services/market.service';

@Injectable()
export class InsightEffects {
  startFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startFeedStreamAction>>(startFeedStreamAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const marketFeed: MarketFeed = JSON.parse(message.data);

            this.insightStore$.dispatch(setMarketFeedAction({ marketFeed }));
          };

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  stopFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopFeedStreamAction>>(stopFeedStreamAction),
        switchMap(() => {
          this.feedService.stopFeed();

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadMarketHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadMarketHistoryAction>>(loadMarketHistoryAction),
      switchMap(() => {
        return this.insightService
          .getDashboardHistory()
          .pipe(map((marketHistory: MarketHistory) => setMarketHistoryAction({ marketHistory })));
      })
    )
  );

  loadLiveBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLiveBalancesAction>>(loadLiveBalancesAction),
      switchMap(() => {
        return this.balanceService.getLiveBalances().pipe(
          map((liveBalances: LiveBalanceResponse) => {
            return setLiveBalancesAction({ liveBalances });
          })
        );
      })
    )
  );

  loadYieldOpportunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadYieldOpportunitiesAction>>(loadYieldOpportunitiesAction),
      switchMap(() => {
        return this.yieldService.getYieldOpportunities().pipe(
          map((yieldOpportunities: YieldOpportunity[]) => {
            return setYieldOpportunitiesAction({ yieldOpportunities });
          })
        );
      })
    )
  );

  loadUserFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserFeesAction>>(loadUserFeesAction),
      switchMap(() => {
        return this.feeService.getUserFees().pipe(
          map((userFees: UserFees) => {
            return setUserFeesAction({ userFees });
          })
        );
      })
    )
  );

  loadUserFeesByPlatform$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserFeesByPlatformAction>>(loadUserFeesByPlatformAction),
      switchMap(() => {
        return this.feeService.getUserFeesByPlatform().pipe(
          map((userFeesByPlatform: Map<string, number>) => {
            return setUserFeesByPlatformAction({ userFeesByPlatform });
          })
        );
      })
    )
  );

  computePortfolioValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computePortfolioValueAction>>(computePortfolioValueAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectLiveBalances)),
        this.insightStore$.pipe(select(fromInsight.selectMarketFeed)),
        this.sharedStore$.pipe(select(fromShared.selectUserScamList)),
        this.sharedStore$.pipe(select(fromShared.selectUserReportedTokenList))
      ),
      map(
        ([action, liveBalances, marketFeed, userScamList, userReportedTokenList]: [
          ReturnType<typeof computePortfolioValueAction>,
          LiveBalanceResponse,
          MarketFeed,
          Scam[],
          ReportedToken[],
        ]) => {
          const portfolioValue = liveBalances.items
            .filter(
              (balance: LiveBalanceResponseItem) =>
                !this.marketService.isScam(balance, userScamList, userReportedTokenList)
            )
            .map((balance: LiveBalanceResponseItem) => {
              const currentPrice = this.marketService.getTokenPrice(marketFeed.marketFeed, balance.token);
              const amount = currentPrice * balance.quantity;
              return amount;
            })
            .reduce((acc: number, amount: number) => {
              return acc + amount;
            }, 0);

          return setPortfolioValueAction({ portfolioValue });
        }
      )
    )
  );

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly actions$: Actions,
    private readonly feedService: FeedService,
    private readonly insightService: InsightService,
    private readonly balanceService: BalanceService,
    private readonly marketService: MarketService,
    private readonly yieldService: YieldService,
    private readonly feeService: FeeService
  ) {}
}
