/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { map, withLatestFrom } from 'rxjs';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { Scam } from '../../../shared/models/scam.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import {
  LiveBalanceResponse,
  LiveBalanceResponseAccount,
  LiveBalanceResponseItem,
  YieldPosition,
} from '../../models/live-balance.model';
import { MarketFeed } from '../../models/market.model';
import { PlatformFee, UserFees } from '../../models/user-fees.model';
import { Yield, YieldOpportunity } from '../../models/yield-opportunity.model';
import { MarketService } from '../../services/market.service';
import {
  computeAnnualFeesAction,
  computeAvailableYieldsAction,
  computeCheapestPlatformsAction,
  computeMissedPassiveYieldAction,
  computePlatformsFeesAction,
  computeYieldableTokensAction,
  setAnnualFeesAction,
  setAvailableYieldsAction,
  setCheapestPlatformsAction,
  setMissedPassiveYieldAction,
  setPlatformsFeesAction,
  setYieldableTokensAction,
} from '../actions/opportunity.action';
import * as fromInsight from '../selectors/insight.selector';
import * as fromOpportunity from '../selectors/opportunity.selector';

@Injectable()
export class OpportunityEffects {
  computeYieldableTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeYieldableTokensAction>>(computeYieldableTokensAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectLiveBalances)),
        this.sharedStore$.pipe(select(fromShared.selectUserScamList)),
        this.sharedStore$.pipe(select(fromShared.selectUserReportedTokenList))
      ),
      map(
        ([action, liveBalances, userScamList, userReportedTokenList]: [
          ReturnType<typeof computeYieldableTokensAction>,
          LiveBalanceResponse,
          Scam[],
          ReportedToken[],
        ]) => {
          const yieldableTokens: Map<string, number> = new Map<string, number>([]);

          liveBalances.items
            .filter(
              (balance: LiveBalanceResponseItem) =>
                !this.marketService.isScam(balance, userScamList, userReportedTokenList)
            )
            .forEach((balance: LiveBalanceResponseItem) => {
              const account: LiveBalanceResponseAccount = liveBalances.accounts.find(
                (acc: LiveBalanceResponseAccount) => acc.accountId === balance.accountId
              );

              const yieldPositions: YieldPosition[] = account.yieldPositions?.filter(
                (position: YieldPosition) => position.token === balance.token
              );

              let quantity = balance.quantity;
              if (yieldPositions?.length > 0) {
                yieldPositions.forEach((position: YieldPosition) => {
                  quantity -= position.quantity;
                });
              }

              if (quantity > 0) {
                const yieldableTokenQuantity = yieldableTokens.get(balance.token);
                if (yieldableTokenQuantity) {
                  yieldableTokens.set(balance.token, yieldableTokenQuantity + quantity);
                } else {
                  yieldableTokens.set(balance.token, quantity);
                }
              }
            });

          return setYieldableTokensAction({ yieldableTokens });
        }
      )
    )
  );

  computeAvailableYields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeAvailableYieldsAction>>(computeAvailableYieldsAction),
      withLatestFrom(
        this.opportunityStore$.pipe(select(fromOpportunity.selectYieldableTokens)),
        this.insightStore$.pipe(select(fromInsight.selectYieldOpportunities)),
        this.insightStore$.pipe(select(fromInsight.selectMarketFeed))
      ),
      map(
        ([action, yieldableTokens, yieldOpportunities, marketFeed]: [
          ReturnType<typeof computeAvailableYieldsAction>,
          Map<string, number>,
          YieldOpportunity[],
          MarketFeed,
        ]) => {
          const availableYields: Yield[] = [];

          yieldableTokens.forEach((quantity: number, token: string) => {
            const yieldOpportunity: YieldOpportunity = this.marketService.getBestYieldOpportunity(
              yieldOpportunities,
              token
            );

            if (yieldOpportunity) {
              const price = this.marketService.getTokenPrice(marketFeed.marketFeed, token);
              const amount = quantity * price;
              const yieldQuantity = this.marketService.computeYieldQuantity(quantity, yieldOpportunity.apy);
              const yieldAmount = yieldQuantity * price;

              availableYields.push({
                platform: yieldOpportunity.platform,
                product: yieldOpportunity.product,
                duration: yieldOpportunity.duration,
                token: yieldOpportunity.token,
                apy: yieldOpportunity.apy,
                quantity,
                amount,
                yieldAmount,
                yieldQuantity,
              });
            }
          });

          return setAvailableYieldsAction({ availableYields });
        }
      )
    )
  );

  computeMissedPassiveYield$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeMissedPassiveYieldAction>>(computeMissedPassiveYieldAction),
      withLatestFrom(this.opportunityStore$.pipe(select(fromOpportunity.selectAvailableYields))),
      map(([action, availableYields]: [ReturnType<typeof computeMissedPassiveYieldAction>, Yield[]]) => {
        const missedPassiveYield = availableYields
          ?.map((availableYield: Yield) => availableYield.yieldAmount)
          .reduce((acc: number, amount: number) => acc + amount, 0);

        return setMissedPassiveYieldAction({ missedPassiveYield });
      })
    )
  );

  computePlatformsFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computePlatformsFeesAction>>(computePlatformsFeesAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectUserFees)),
        this.insightStore$.pipe(select(fromInsight.selectUserFeesByPlatform))
      ),
      map(
        ([action, userFees, userFeesByPlatform]: [
          ReturnType<typeof computePlatformsFeesAction>,
          UserFees,
          Map<string, number>,
        ]) => {
          const platformsFees: PlatformFee[] = [...userFees.feeByPlatform.entries()].map((value: [string, number]) => {
            const name = value[0];
            const fees = value[1];
            const volume: number = userFees.volumeByPlatform.get(name);
            const averageFees = volume > 0 ? (fees / volume) * 100 : 0;
            const waltioAverageFees = userFeesByPlatform.get(name);

            return {
              name,
              fees,
              averageFees,
              waltioAverageFees,
              volume,
            };
          });

          platformsFees.sort((a: PlatformFee, b: PlatformFee) => b.averageFees - a.averageFees);

          return setPlatformsFeesAction({ platformsFees });
        }
      )
    )
  );

  computeCheapestPlatforms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeCheapestPlatformsAction>>(computeCheapestPlatformsAction),
      withLatestFrom(this.insightStore$.pipe(select(fromInsight.selectUserFees))),
      map(([action, userFees]: [ReturnType<typeof computeCheapestPlatformsAction>, UserFees]) => {
        const cheapestPlatforms: PlatformFee[] = [];

        userFees.bestFeeByPlatform.forEach((fee: number, platform: string) => {
          const platformFee: PlatformFee = {
            name: platform,
            waltioAverageFees: fee,
          };

          cheapestPlatforms.push(platformFee);
        });

        cheapestPlatforms.sort((a: PlatformFee, b: PlatformFee) => a.waltioAverageFees - b.waltioAverageFees);

        return setCheapestPlatformsAction({ cheapestPlatforms });
      })
    )
  );

  computeAnnualFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeAnnualFeesAction>>(computeAnnualFeesAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectUserFees)),
        this.opportunityStore$.pipe(select(fromOpportunity.selectCheapestPlatforms)),
        this.opportunityStore$.pipe(select(fromOpportunity.selectPlatformsFees))
      ),
      map(
        ([action, userFees, cheapestPlatforms, platformsFees]: [
          ReturnType<typeof computeAnnualFeesAction>,
          UserFees,
          PlatformFee[],
          PlatformFee[],
        ]) => {
          let bestAnnualFees = 0;
          let worstAnnualFees = 0;

          if (userFees.volume !== 0) {
            const fees = 0;
            const cheapestPlatform = cheapestPlatforms[0];
            const mostExpensivePlatform = cheapestPlatforms[cheapestPlatforms.length - 1];
            const cheapestPlatformFees = cheapestPlatform.waltioAverageFees;
            const mostExpensivePlatformFees = mostExpensivePlatform.waltioAverageFees;

            platformsFees.forEach((platformFee: PlatformFee) => {
              const paidFees = platformFee.fees;
              const platformAverageFees = platformFee.averageFees;

              if (platformAverageFees > cheapestPlatformFees) {
                const savedFees = (paidFees * cheapestPlatformFees) / platformAverageFees;
                bestAnnualFees += paidFees - savedFees;
              }

              if (platformAverageFees > mostExpensivePlatformFees) {
                const savedFees = (paidFees * mostExpensivePlatformFees) / platformAverageFees;
                worstAnnualFees += paidFees - savedFees;
              }
            });
          }

          const currentYear = moment().year();
          const nbOfYears = currentYear - userFees.activeFrom;
          if (nbOfYears > 0) {
            bestAnnualFees /= nbOfYears;
            worstAnnualFees /= nbOfYears;
          }

          return setAnnualFeesAction({ bestAnnualFees, worstAnnualFees });
        }
      )
    )
  );

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly opportunityStore$: Store<fromOpportunity.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly actions$: Actions,
    private readonly marketService: MarketService
  ) {}
}
