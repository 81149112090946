import { Action, createReducer, on } from '@ngrx/store';
import * as OpportunityActions from '../actions/opportunity.action';
import { PlatformFee } from '../../models/user-fees.model';
import { Yield } from '../../models/yield-opportunity.model';

export interface State {
  yieldableTokens: Map<string, number>;
  missedPassiveYield: number;
  bestAnnualFees: number;
  worstAnnualFees: number;
  cheapestPlatforms: PlatformFee[];
  availableYields: Yield[];
  platformsFees: PlatformFee[];
}

export const initialState: State = {
  yieldableTokens: null,
  missedPassiveYield: null,
  bestAnnualFees: null,
  worstAnnualFees: null,
  cheapestPlatforms: null,
  availableYields: null,
  platformsFees: null,
};

const reducer = createReducer(
  initialState,
  on(OpportunityActions.setYieldableTokensAction, (state: State, { yieldableTokens }: any) => ({
    ...state,
    yieldableTokens,
  })),
  on(OpportunityActions.setAvailableYieldsAction, (state: State, { availableYields }: any) => ({
    ...state,
    availableYields,
  })),
  on(OpportunityActions.setMissedPassiveYieldAction, (state: State, { missedPassiveYield }: any) => ({
    ...state,
    missedPassiveYield,
  })),
  on(OpportunityActions.setCheapestPlatformsAction, (state: State, { cheapestPlatforms }: any) => ({
    ...state,
    cheapestPlatforms,
  })),
  on(OpportunityActions.setAnnualFeesAction, (state: State, { bestAnnualFees, worstAnnualFees }: any) => ({
    ...state,
    bestAnnualFees,
    worstAnnualFees,
  })),
  on(OpportunityActions.setPlatformsFeesAction, (state: State, { platformsFees }: any) => ({
    ...state,
    platformsFees,
  }))
);

export const opportunityReducer = (state: State | undefined, action: Action): State => reducer(state, action);
