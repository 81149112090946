import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { TypeformDialogComponent } from '../../../shared/components/dialogs/typeform-dialog/typeform-dialog.component';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { Scam } from '../../../shared/models/scam.model';
import { UtilsService } from '../../../shared/services/utils.service';
import { loadUserReportedTokenListAction, loadUserScamListAction } from '../../../shared/store/actions/shared.action';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { OpportunitiesHeaderComponent } from '../../components/opportunities-header/opportunities-header.component';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { MarketFeed } from '../../models/market.model';
import { PlatformFee, UserFees } from '../../models/user-fees.model';
import { YieldOpportunity } from '../../models/yield-opportunity.model';
import {
  computePortfolioValueAction,
  loadLiveBalancesAction,
  loadUserFeesAction,
  loadUserFeesByPlatformAction,
  loadYieldOpportunitiesAction,
  startFeedStreamAction,
  stopFeedStreamAction,
} from '../../store/actions/insight.action';
import * as fromInsight from '../../store/selectors/insight.selector';

@Component({
  selector: `app-opportunities-page`,
  standalone: true,
  imports: [CommonModule, RouterModule, OpportunitiesHeaderComponent],
  templateUrl: `./opportunities-page.component.html`,
})
export class OpportunitiesPageComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  marketFeed: MarketFeed;
  liveBalances: LiveBalanceResponse;
  yieldOpportunities: YieldOpportunity[];
  userFees: UserFees;
  userScamList: Scam[];
  userReportedTokenList: ReportedToken[];

  cheapestPlatforms: PlatformFee[];
  userFeesByPlatform: Map<string, number>;
  platformsFees: PlatformFee[] = [];

  liveBalancesRetryInterval: any;

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.insightStore$.dispatch(startFeedStreamAction());
    this.insightStore$.dispatch(loadLiveBalancesAction());
    this.sharedStore$.dispatch(loadUserScamListAction());
    this.sharedStore$.dispatch(loadUserReportedTokenListAction());

    this.insightStore$.dispatch(loadYieldOpportunitiesAction());

    this.insightStore$.dispatch(loadUserFeesAction());
    this.insightStore$.dispatch(loadUserFeesByPlatformAction());

    combineLatest([
      this.insightStore$.select(fromInsight.selectMarketFeed),
      this.insightStore$.select(fromInsight.selectLiveBalances),
      this.sharedStore$.select(fromShared.selectUserScamList),
      this.sharedStore$.select(fromShared.selectUserReportedTokenList),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([marketFeed, liveBalances, userScamList, userReportedTokenList]: [
            MarketFeed,
            LiveBalanceResponse,
            Scam[],
            ReportedToken[],
          ]) => {
            this.marketFeed = marketFeed;
            this.liveBalances = liveBalances;
            this.userScamList = userScamList;
            this.userReportedTokenList = userReportedTokenList;

            if (this.marketFeed && this.liveBalances && this.userScamList && this.userReportedTokenList) {
              this.insightStore$.dispatch(computePortfolioValueAction());

              this.refreshLiveBalances();
            }
          }
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.insightStore$.dispatch(stopFeedStreamAction());
    clearInterval(this.liveBalancesRetryInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshLiveBalances(): void {
    // Create refresh balances interval
    clearInterval(this.liveBalancesRetryInterval);

    if (this.liveBalances.retryIn > 0) {
      this.liveBalancesRetryInterval = setInterval(() => {
        this.insightStore$.dispatch(loadLiveBalancesAction());
      }, this.liveBalances.retryIn);
    }
  }

  openFeedbackDialog(): void {
    this.utilsService.openDialog(TypeformDialogComponent, `1050px`, `573px`, {
      typeformId: `HdV0ROXG`,
    });
  }
}
