import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as insightReducer from '../reducers/insight.reducer';
import { MarketFeed, MarketHistory } from '../../models/market.model';
import { YieldOpportunity } from '../../models/yield-opportunity.model';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { UserFees } from '../../models/user-fees.model';

export const INSIGHT_FEATURE_NAME = `insight`;

export interface State {
  insight: insightReducer.State;
}

export const selectInsightState: MemoizedSelector<State, insightReducer.State> =
  createFeatureSelector<insightReducer.State>(INSIGHT_FEATURE_NAME);

export const selectPortfolioValue: MemoizedSelector<State, number> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.portfolioValue
);

export const selectMarketHistory: MemoizedSelector<State, MarketHistory> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.marketHistory
);

export const selectMarketFeed: MemoizedSelector<State, MarketFeed> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.marketFeed
);

export const selectLiveBalances: MemoizedSelector<State, LiveBalanceResponse> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.liveBalances
);

export const selectYieldOpportunities: MemoizedSelector<State, YieldOpportunity[]> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.yieldOpportunities
);

export const selectUserFees: MemoizedSelector<State, UserFees> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.userFees
);

export const selectUserFeesByPlatform: MemoizedSelector<State, Map<string, number>> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.userFeesByPlatform
);
