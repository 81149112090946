/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { MarketFeed, MarketHistory } from '../../models/market.model';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { YieldOpportunity } from '../../models/yield-opportunity.model';
import { UserFees } from '../../models/user-fees.model';

// eslint-disable-next-line no-shadow
export enum InsightActions {
  StartFeedStream = `[Insight] Start Feed Stream`,
  StopFeedStream = `[Insight] Stop Feed Stream`,
  SetMarketFeed = `[Insight] Set Market Feed`,
  LoadMarketHistory = `[Insight] Load Market History`,
  SetMarketHistory = `[Insight] Set Market History`,
  LoadLiveBalances = `[Insight] Load Live Balances`,
  SetLiveBalances = `[Insight] Set Live Balances`,
  LoadYieldOpportunities = `[Insight] Load Yield Opportunities`,
  SetYieldOpportunities = `[Insight] Set Yield Opportunities`,
  LoadUserFees = `[Insight] Load User Fees`,
  SetUserFees = `[Insight] Set User Fees`,
  LoadUserFeesByPlatform = `[Insight] Load User Fees By Platform`,
  SetUserFeesByPlatform = `[Insight] Set User Fees By Platform`,

  ComputePortfolioValue = `[Insight] Compute Portfolio Value`,
  SetPortfolioValue = `[Insight] Set Portfolio Value`,
}

export const computePortfolioValueAction = createAction(InsightActions.ComputePortfolioValue);

export const setPortfolioValueAction = createAction(
  InsightActions.SetPortfolioValue,
  props<{ portfolioValue: number }>()
);

export const startFeedStreamAction = createAction(InsightActions.StartFeedStream);

export const stopFeedStreamAction = createAction(InsightActions.StopFeedStream);

export const setMarketFeedAction = createAction(InsightActions.SetMarketFeed, props<{ marketFeed: MarketFeed }>());

export const loadMarketHistoryAction = createAction(InsightActions.LoadMarketHistory);

export const setMarketHistoryAction = createAction(
  InsightActions.SetMarketHistory,
  props<{ marketHistory: MarketHistory }>()
);

export const loadLiveBalancesAction = createAction(InsightActions.LoadLiveBalances);

export const setLiveBalancesAction = createAction(
  InsightActions.SetLiveBalances,
  props<{ liveBalances: LiveBalanceResponse }>()
);

export const loadYieldOpportunitiesAction = createAction(InsightActions.LoadYieldOpportunities);

export const setYieldOpportunitiesAction = createAction(
  InsightActions.SetYieldOpportunities,
  props<{ yieldOpportunities: YieldOpportunity[] }>()
);

export const loadUserFeesAction = createAction(InsightActions.LoadUserFees);

export const setUserFeesAction = createAction(InsightActions.SetUserFees, props<{ userFees: UserFees }>());

export const loadUserFeesByPlatformAction = createAction(InsightActions.LoadUserFeesByPlatform);

export const setUserFeesByPlatformAction = createAction(
  InsightActions.SetUserFeesByPlatform,
  props<{ userFeesByPlatform: Map<string, number> }>()
);
